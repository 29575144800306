import Typography from '@mui/material/Typography';
import FitnessFileType from '../../scripts/fitnessFileType';
import ResourceTablePage from './shared/ResourceTablePage';

const fitnessFileType =
	FitnessFileType.ashlinkFitnessProviderServicesAgreementResource.value;

const FitnessServicesAgreement = () => {
	const pageTitle = 'Fitness Services Agreement';
	const resourceColumnTitle = 'Fitness Services Agreement';
	const pageSummary = (
		<Typography mb={2} fontWeight='bold'>
			The ASH Fitness Services Agreement goes into detail about fitness
			center obligations agreed to for participation in our fitness
			network. The table of contents gives a quick overview of all
			sections of the Fitness Services Agreement. Other portions of the
			Fitness Services Agreement including the Program Manual, Client
			Summaries are also posted in the Resources section.
		</Typography>
	);

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			pageSummary={pageSummary}
			resourceColumnTitle={resourceColumnTitle}
			fitnessFileType={fitnessFileType}
			disableReleaseDateColumn
		/>
	);
};

export default FitnessServicesAgreement;
