import { FITNESS_PAGE_ROUTES, TOP_LEVEL_ROUTES } from './routes';

export const pageKey = {
	// Home
	home: 'home',

	// Member Management
	memberElgibilitySearch: 'member-elgibility-search',
	memberTerminationReport: 'member-termination-report',

	// Account
	accountManagement: 'account-management',
	confirmUpdateInformation: 'confirm-update-information',
	messageCenter: 'message-center',
	manageSubAccounts: 'manage-sub-accounts',
	favoriteCptCodes: 'favorite-cpt-codes',
	updateInfo: 'update-info',

	// Resources
	clientSummary: 'client-summary',
	compliance: 'compliance',
	fitnessInstructorServiceAgreement: 'fitness-instructor-service-agreement',
	fitnessServicesAgreement: 'fitness-services-agreement',
	notification: 'notification',
	programEducationLibrary: 'program-education-library',
	programManual: 'program-manual',
	forms: 'resources-form',

	// Billing and Payment
	claimsAndPayments: 'claim-and-payment',
	memberBillingReport: 'member-billing-report',
	bulkClaimSummary: 'bulk-claim-summary',
	bulkClaimUpload: 'bulk-claim-upload',

	// Help
	ASHLinkWebsiteTip: 'ashlink-website-tip',
	contactUs: 'contact-us',
	frequentlyAskedQuestion: 'frequently-asked-question',
	submitAnOnlineQuestionOrComment: 'submit-question-or-comment',

	// Welcome Packet
	welcomePacket: 'welcome-packet',

	// Footer
	privacyStatement: 'privacy-statement',
	termsAndCondition: 'terms-and-condition',
};

export const permissionName = {
	// General
	general: 'General',

	// Member Management
	memberElgibilitySearch: 'MemberElgibilitySearch',
	memberTerminationReport: 'MemberTerminationReport',

	// Account
	fitnessInstructorInformationValidation:
		'FitnessInstructorInformationValidation',
	fitnessCenterInformationValidation: 'FitnessCenterInformationValidation',
	generalUserInformation: 'GeneralUserInformation',
	linkedUser: 'LinkedUser',
	messageCenter: 'MessageCenter',

	// Resources
	clientSummary: 'ClientSummary',
	compliance: 'Compliance',
	form: 'Form',
	fitnessInstructorServiceAgreement: 'FitnessInstructorServiceAgreement',
	fitnessServicesAgreement: 'FitnessServicesAgreement',
	notification: 'Notification',
	programEducationLibrary: 'ProgramEducationLibrary',
	programManual: 'ProgramManual',

	// Billing and Payments
	bulkClaim: 'BulkClaim',
	claim: 'Claim',
	memberBillingReport: 'MemberBillingReport',
	payment: 'Payment',

	// Welcome Packet
	welcomePacket: 'WelcomePacket',
};

export const permissionOperation = {
	read: 'Read',
	createUpdate: 'CreateUpdate',
	delete: 'Delete',
};

export const retrievePermissionWithKey = {
	// Home
	[`${pageKey.home}`]: `${permissionName.general}:${permissionOperation.read}`,

	// Member Management
	[`${pageKey.memberElgibilitySearch}`]: `${permissionName.memberElgibilitySearch}:${permissionOperation.read}`,
	[`${pageKey.memberTerminationReport}`]: `${permissionName.memberTerminationReport}:${permissionOperation.read}`,

	// Account
	[`${pageKey.accountManagement}`]: [
		`${permissionName.linkedUser}:${permissionOperation.read}`,
		`${permissionName.generalUserInformation}:${permissionOperation.read}`,
	],
	[`${pageKey.confirmUpdateInformation}`]: [
		`${permissionName.fitnessCenterInformationValidation}:${permissionOperation.createUpdate}`,
		`${permissionName.fitnessCenterInformationValidation}:${permissionOperation.read}`,
		`${permissionName.fitnessInstructorInformationValidation}:${permissionOperation.createUpdate}`,
		`${permissionName.fitnessInstructorInformationValidation}:${permissionOperation.read}`,
	],
	[`${pageKey.messageCenter}`]: `${permissionName.messageCenter}:${permissionOperation.read}`,
	[`${pageKey.manageSubAccounts}`]: `${permissionName.ASHCareSSO}:${permissionOperation.read}`, // TODO
	[`${pageKey.favoriteCptCodes}`]: `${permissionName.ASHCareSSO}:${permissionOperation.read}`, // TODO
	[`${pageKey.updateInfo}`]: `${permissionName.ASHCareSSO}:${permissionOperation.read}`, // TODO

	// Resources
	[`${pageKey.clientSummary}`]: `${permissionName.clientSummary}:${permissionOperation.read}`,
	[`${pageKey.compliance}`]: `${permissionName.compliance}:${permissionOperation.read}`,
	[`${pageKey.fitnessInstructorServiceAgreement}`]: `${permissionName.fitnessInstructorServiceAgreement}:${permissionOperation.read}`,
	[`${pageKey.fitnessServicesAgreement}`]: `${permissionName.fitnessServicesAgreement}:${permissionOperation.read}`,
	[`${pageKey.forms}`]: `${permissionName.form}:${permissionOperation.read}`,
	[`${pageKey.notification}`]: `${permissionName.notification}:${permissionOperation.read}`,
	[`${pageKey.programEducationLibrary}`]: `${permissionName.programEducationLibrary}:${permissionOperation.read}`,
	[`${pageKey.programManual}`]: `${permissionName.programManual}:${permissionOperation.read}`,

	// Billing and Payment
	[`${pageKey.bulkClaimSummary}`]: `${permissionName.bulkClaim}:${permissionOperation.read}`,
	[`${pageKey.bulkClaimUpload}`]: `${permissionName.bulkClaim}:${permissionOperation.createUpdate}`,
	[`${pageKey.claimsAndPayments}`]: [
		`${permissionName.claim}:${permissionOperation.read}`,
		`${permissionName.payment}:${permissionOperation.read}`,
	],
	[`${pageKey.memberBillingReport}`]: `${permissionName.memberBillingReport}:${permissionOperation.read}`,

	// Help
	[`${pageKey.ASHLinkWebsiteTip}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.contactUs}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.frequentlyAskedQuestion}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.submitAnOnlineQuestionOrComment}`]: `${permissionName.messageCenter}:${permissionOperation.createUpdate}`,

	// Welcome Packet
	[`${pageKey.welcomePacket}`]: `${permissionName.welcomePacket}:${permissionOperation.read}`,

	// Footer
	[`${pageKey.termsAndCondition}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.privacyStatement}`]: `${permissionName.general}:${permissionOperation.read}`,
};

export const retrievePageKeyWithRoute = {
	// Resources
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.clientSummaries}`.toLowerCase()]:
		pageKey.clientSummary,
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.compliance}`.toLowerCase()]:
		pageKey.compliance,
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.fitnessInstructorServicesAgreements}`.toLowerCase()]:
		pageKey.fitnessInstructorServiceAgreement,
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.fitnessServicesAgreement}`.toLowerCase()]:
		pageKey.fitnessServicesAgreement,
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.forms}`.toLowerCase()]:
		pageKey.forms,
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.notifications}`.toLowerCase()]:
		pageKey.notification,
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.programEducationLibrary}`.toLowerCase()]:
		pageKey.programEducationLibrary,
	[`/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.programManual}`.toLowerCase()]:
		pageKey.programManual,

	// Help
	[`/${TOP_LEVEL_ROUTES.help}/${FITNESS_PAGE_ROUTES.faq}`.toLowerCase()]:
		pageKey.frequentlyAskedQuestion,

	// Welcome Packet
	[`/${TOP_LEVEL_ROUTES.welcomePacket}`.toLowerCase()]: pageKey.welcomePacket,
};
