import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
import SideNavigation from '../SideNavigation/SideNavigation';
import useIsMobile from '../../../hooks/useIsMobile';

const hasNavItemsAndPermissions = (navItems, permissions) =>
	navItems &&
	navItems.length > 0 &&
	permissions &&
	Object.keys(permissions).length > 0;

const PageLayout = ({ pageTitle, children, navItems, permissions }) => {
	const isMobile = useIsMobile();

	return (
		<EveThemeProvider themeKey='internal' themeMode='light'>
			<Container>
				<Typography variant='h4' component='h1' mb={2} pt={3}>
					{pageTitle}
				</Typography>
				<Divider sx={{ mb: 4 }} />
				<Grid container direction='row' spacing={3}>
					{!isMobile &&
						hasNavItemsAndPermissions(navItems, permissions) && (
							<Grid item>
								<SideNavigation
									pages={navItems}
									permissions={permissions}
								/>
							</Grid>
						)}
					<Grid item xs>
						{children}
						<Outlet />
					</Grid>
				</Grid>
			</Container>
		</EveThemeProvider>
	);
};

PageLayout.defaultProps = {
	children: undefined,
	navItems: [],
	permissions: {},
};
export default PageLayout;

PageLayout.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	children: PropTypes.node,
	navItems: PropTypes.array,
	permissions: PropTypes.object,
};
