import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { LOGOUT_URL } from '../../../scripts/navigationItems';
import { GetSessionContext } from '../../../data/apiCalls';

const IdleModal = ({ open, setSignedIn }) => {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		outline: 'none',
		borderRadius: 1,
	};
	const [seconds, setSeconds] = useState(180);
	let countdownTimer;
	const clearTimer = () => {
		window.clearTimeout(countdownTimer);
	};
	useEffect(() => {
		if (seconds > 0 && open) {
			countdownTimer = window.setTimeout(
				() => setSeconds(seconds - 1),
				1000
			);
		} else {
			clearTimer();
		}
	}, [seconds, open]);

	const logOut = () => {
		window.open(LOGOUT_URL, '_self', 'noreferrer');
	};

	const handleLogout = () => {
		logOut();
	};

	const handleSignedIn = async () => {
		try {
			const res = await GetSessionContext();
			if (res.resultCode === 1) logOut();
		} catch (error) {
			return error;
		}
		clearTimer();
		setSeconds(180);
		setSignedIn();
		return null;
	};

	const getTimer = () => new Date(seconds * 1000).toISOString().slice(15, 19);

	return (
		<Modal
			open={open}
			onClose={null}
			aria-labelledby='idle-timeout-modal'
			aria-describedby='idle-timeout-modal'
		>
			<Box sx={style}>
				<Stack spacing={2}>
					<Typography variant='h6' component='h1'>
						{seconds > 0
							? 'Your session is about to expire.'
							: 'Your session has expired.'}
					</Typography>

					<Typography sx={{ fontSize: '16px' }}>
						{seconds > 0
							? `You will be automatically signed out in  ${getTimer()}. Would you like to stay signed in?`
							: 'You have been signed out due to inactivity. Please log in to continue your session.'}
					</Typography>
					<Grid container flexDirection='row-reverse'>
						{seconds > 0 ? (
							<Grid item>
								<Button onClick={handleLogout}>
									SIGN OUT NOW
								</Button>
								<Button
									onClick={handleSignedIn}
									variant='contained'
								>
									STAY SIGNED IN
								</Button>
							</Grid>
						) : (
							<Grid item>
								<Button
									onClick={handleLogout}
									variant='contained'
								>
									OK
								</Button>
							</Grid>
						)}
					</Grid>
				</Stack>
			</Box>
		</Modal>
	);
};

export default IdleModal;

IdleModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setSignedIn: PropTypes.func.isRequired,
};
