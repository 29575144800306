import restDataService from './restDataService';

const ashlinkNextGatewayApiBasePath =
	'api/ashlinknextapigateway/Fitness/PostLogin';

const userManagementApiBasePath = 'api';

export const GetFitnessResourcesFn = async (
	drFedId,
	fitnessFileType,
	pageNumber,
	pageSize
) =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/FitnessResources`, {
			params: {
				DrFedID: drFedId,
				FileType: fitnessFileType,
				Page: pageNumber,
				PageSize: pageSize,
			},
		})
		.catch(error => error.response);

export const GetFAQBySpecialtyFn = async drFedId =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/FAQBySpecialty`, {
			params: {
				DrFedID: drFedId,
			},
		})
		.catch(error => error.response);

export const GetFitnessPageFn = async (drFedId, pageId) =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/FitnessPage`, {
			params: {
				DrFedID: drFedId,
				PageID: pageId,
			},
		})
		.catch(error => error.response);

export const GetSessionContext = async () => {
	try {
		return (await restDataService.get('auth/userparams')).data;
	} catch (error) {
		return error;
	}
};

export const GetStatus = async () => {
	try {
		return (await restDataService.get('auth/sessionstatus')).data;
	} catch (error) {
		return error;
	}
};

export const GetFitnessLocationInfoFn = async (
	apiUrl,
	page,
	pageSize,
	sortModel,
	filterModel,
	rowCount,
	selectAll
) => {
	if (apiUrl !== '') {
		return restDataService
			.get(apiUrl, {
				params: {
					Page: page,
					PageSize: pageSize,
					SortModel: sortModel,
					FilterModel: filterModel,
				},
			})
			.catch(error => error.response);
	}
	const testData = {
		name: 'Big Sky Pediatric Therapy',
		address1: '123 Elm St.',
		address2: 'Suite 300',
		city: 'San Diego',
		state: 'CA',
		zipCode: '92125',
		phone: '8587502427',
		fax: '8587502427',
		taxId: 1002030,
		effective: '09/01/2021',
		termed: '09/01/2021',
	};

	const rows = [];
	const startIndex = page * pageSize;
	for (
		let i = startIndex;
		i < Math.min(startIndex + pageSize, rowCount);
		i += 1
	) {
		rows.push({ id: i, ...testData });
	}
	const selectedRows = selectAll ? [...Array(rowCount).keys()] : [];

	return {
		data: {
			rows,
			rowCount,
			selectedRows,
		},
	};
};
export const GetServiceLocations = async () =>
	restDataService.get(
		`${userManagementApiBasePath}/AshlinkUserProfileByEntity`
	);
