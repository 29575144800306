import Grid from '@mui/material/Grid';
import PageLayout from '../shared/PageLayout/PageLayout';
import useSessionContext from '../../data/queryHooks/useSessionContext';
import { getResourcesNavs } from '../../scripts/navigationHelpers';

const Resources = () => {
	const { permissions, redirectionLinks } = useSessionContext();

	return (
		<Grid container spacing={2}>
			<Grid item xs={0} lg={12}>
				<PageLayout
					pageTitle='Resources'
					navItems={getResourcesNavs(permissions, redirectionLinks)}
					permissions={permissions}
				/>
			</Grid>
		</Grid>
	);
};
export default Resources;
