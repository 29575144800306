import {
	RESOURCES_NAV_ITEMS,
	HELP_NAV_ITEMS,
	PROFILE_ITEMS,
	TOP_NAV_ITEMS,
	FOOTER_ITEMS,
	MESSAGE_CENTER,
	MEMBER_MANAGEMENT_NAV_ITEMS,
	ACCOUNT_NAV_ITEMS,
	BILLING_NAV_ITEMS,
	MY_ACCOUNT_NAV_ITEMS,
} from './navigationItems';
import { pageKey, retrievePermissionWithKey } from './pageConstants';

export const getCurrentPermission = (permissionName, permissions) => {
	const pagePermission = permissions.filter(
		permission => permission === permissionName
	); // [General:Read, ClientSummary:Read]
	return pagePermission.length > 0 ? pagePermission[0] : null;
};

export const getRedirectionLink = (page, redirectionLinks) => {
	const legacyLink =
		redirectionLinks.filter(link => link.pageKey === page)[0]
			?.redirectUrl || null;

	return legacyLink;
};

export const updateItemNavPath = (navs, redirectionLinks) => {
	navs.forEach(nav => {
		const legacyLink = getRedirectionLink(nav.key, redirectionLinks);
		if (legacyLink) {
			nav.path = legacyLink;
			nav.isLegacy = true;
		}
	});
};

const getDropdownNavs = (items, permissions) => {
	const navs = items.filter(
		navItem =>
			!!getCurrentPermission(
				retrievePermissionWithKey[navItem.key],
				permissions
			)
	);
	return navs;
};

const sortNavigationItems = navs => navs.sort((a, b) => a.order - b.order);

const sortNavigationItemsByLabel = navs =>
	navs.sort((a, b) => a.label.localeCompare(b.label));

export const findPermissionInPermissions = (permission, permissions) =>
	permissions.includes(permission);

export const getResourcesNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(RESOURCES_NAV_ITEMS, permissions);
	updateItemNavPath(navs, redirectionLinks);

	sortNavigationItemsByLabel(navs);

	return navs;
};

export const getHelpNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(HELP_NAV_ITEMS, permissions);
	updateItemNavPath(navs, redirectionLinks);

	sortNavigationItems(navs);

	return navs;
};

export const getMemberManagementNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(MEMBER_MANAGEMENT_NAV_ITEMS, permissions);
	updateItemNavPath(navs, redirectionLinks);

	sortNavigationItems(navs);

	return navs;
};

export const getAccountNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(ACCOUNT_NAV_ITEMS, permissions);

	[pageKey.accountManagement, pageKey.confirmUpdateInformation].forEach(
		pk => {
			if (
				retrievePermissionWithKey[pk].some(perm =>
					findPermissionInPermissions(perm, permissions)
				)
			) {
				navs.push(ACCOUNT_NAV_ITEMS.find(item => item.key === pk));
			}
		}
	);

	updateItemNavPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};

export const getBillingAndPaymentNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(BILLING_NAV_ITEMS, permissions);
	if (
		retrievePermissionWithKey[pageKey.claimsAndPayments].some(perm =>
			findPermissionInPermissions(perm, permissions)
		)
	) {
		navs.push(
			BILLING_NAV_ITEMS.find(
				item => item.key === pageKey.claimsAndPayments
			)
		);
	}
	updateItemNavPath(navs, redirectionLinks);

	sortNavigationItems(navs);

	return navs;
};

const profileNavsHelper = (pagePermissions, permissions, navs, key) => {
	pagePermissions.every(permission => {
		if (findPermissionInPermissions(permission, permissions)) {
			navs.push(PROFILE_ITEMS.filter(item => item.key === key)[0]);
			return false;
		}
		return true;
	});
};

export const getMessageCenterPath = redirectionLinks => {
	updateItemNavPath([MESSAGE_CENTER], redirectionLinks);
	return MESSAGE_CENTER.path;
};

export const getProfileNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(PROFILE_ITEMS, permissions);

	profileNavsHelper(
		retrievePermissionWithKey[pageKey.accountManagement],
		permissions,
		navs,
		pageKey.accountManagement
	);

	updateItemNavPath(navs, redirectionLinks);
	sortNavigationItems(navs);
	return navs;
};

export const getFooterNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(FOOTER_ITEMS, permissions);
	updateItemNavPath(navs, redirectionLinks);

	return navs;
};

const headerNavsHelper = (navs, items, navKey, redirectionLinks) => {
	if (items && items.length > 0) {
		const topNav = TOP_NAV_ITEMS.filter(
			navItem => navItem.key === navKey
		)[0];
		topNav.menuItems = items;
		const redirectLink = getRedirectionLink(items[0].key, redirectionLinks);
		topNav.path = redirectLink || items[0].path;
		navs.push(topNav);
	}
};

export const getHeaderNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(TOP_NAV_ITEMS, permissions);

	headerNavsHelper(
		navs,
		getResourcesNavs(permissions, redirectionLinks),
		'resources',
		redirectionLinks
	);

	headerNavsHelper(
		navs,
		getHelpNavs(permissions, redirectionLinks),
		'help',
		redirectionLinks
	);

	headerNavsHelper(
		navs,
		getMemberManagementNavs(permissions, redirectionLinks),
		'member-management',
		redirectionLinks
	);

	headerNavsHelper(
		navs,
		getBillingAndPaymentNavs(permissions, redirectionLinks),
		'billing-and-payments',
		redirectionLinks
	);

	updateItemNavPath(navs, redirectionLinks);

	sortNavigationItems(navs);
	return navs;
};

export const getHomePagePath = (permissions, redirectionLinks) => {
	const homePath = getHeaderNavs(permissions, redirectionLinks).filter(
		item => item.key === pageKey.home
	)[0]?.path;
	return homePath;
};

export const getMyAccountNavs = (permissions, redirectionLinks) => {
	const navs = getDropdownNavs(MY_ACCOUNT_NAV_ITEMS, permissions);
	updateItemNavPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};
