// 2023-12-11: Team decided to use camel-case for all route values
export const TOP_LEVEL_ROUTES = {
	public: 'public',
	resources: 'resources',
	help: 'help',
	welcomePacket: 'welcomePacket',
	account: 'account',
};

export const FITNESS_PAGE_ROUTES = {
	pageNotFound: 'pageNotFound',
	// Resources
	clientSummaries: 'clientSummaries',
	notifications: 'notifications',
	fitnessServicesAgreement: 'fitnessServicesAgreement',
	programEducationLibrary: 'programEducationLibrary',
	practitionerServiceAgreements: 'practitionerServicesAgreements',
	fitnessInstructorServicesAgreements: 'fitnessInstructorServicesAgreements',
	programManual: 'programManual',
	forms: 'forms',
	compliance: 'compliance',
	// Help
	faq: 'frequentlyAskedQuestions',
	// Account
	editSubAccounts: 'editSubAccounts',
};

export const PAGE_PATHS = {
	pageNotFound: `/${TOP_LEVEL_ROUTES.public}/${FITNESS_PAGE_ROUTES.pageNotFound}`,
};
