/**
 * matches and groups any phone number into 4 groups:
 * - country code: $1
 * - area code: $2
 * - prefix: $3
 * - line number: $4
 *
 * @param {string} phone - the phone number to format
 * @param {string} format - the format of the phone  (default: `'$2-$3-$4'`)
 * @returns {string} the formatted phone number
 */
export const formatPhone = (phone, format = '$2-$3-$4') =>
	phone?.replace(
		/^\+?([0-9]{0,2})[\s.-]?\(?([0-9]{3})\)?[\s.-]?([0-9]{3})[\s.-]?([0-9]{4}).*$/,
		format
	);

/**
 * Formats an address into a single string
 *
 * @param {string} address1
 * @param {string} address2
 * @param {string} city
 * @param {string} state
 * @param {string} zipCode
 * @returns formatted address
 */
export const formatAddress = (address1, address2, city, state, zipCode) =>
	`${address1}${
		address2 ? ` ${address2}` : ''
	}, ${city}, ${state} ${zipCode}`;
